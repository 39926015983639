var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Text, Row } from 'elemental-react';
import { Section } from '@elemental-zcash/style-guide';
import DefaultButton from '@elemental-zcash/components/lib/buttons/DefaultButton';
import OutlinedButton from '@elemental-zcash/components/lib/buttons/OutlinedButton';
import TextButton from '@elemental-zcash/components/lib/buttons/TextButton';
var subtitleLocales = {
    filled: 'Filled Buttons',
    outlined: 'Outlined Buttons',
    text: 'Text Buttons',
};
var Buttons = function (_a) {
    var components = _a.components;
    var _b = (components || {}).theme, theme = _b === void 0 ? 'light' : _b;
    var ButtonByShape = {
        filled: DefaultButton,
        outlined: OutlinedButton,
        text: TextButton,
    };
    var buttonPropByTag = {
        hovered: { pseudoState: 'hover' },
        focused: { pseudoState: 'focus' },
        pressed: { pseudoState: 'focus' },
        disabled: { pseudoState: 'disabled' },
    };
    return (_jsx(_Fragment, { children: _jsx(Section, __assign({ title: "Buttons", titleColor: theme === 'dark' ? '#fff' : '#000' }, { children: _jsx(Box, __assign({ bg: "test", p: 40 }, { children: _jsx(Box, { children: ['filled', 'outlined', 'text'].map(function (shape) {
                        var _Button = ButtonByShape[shape];
                        return (_jsxs(Box, __assign({ bg: "test", mb: 20 }, { children: [_jsx(Text, __assign({ fontFamily: "Roboto", fontSize: 16, fontWeight: 500, mb: 12 }, { children: subtitleLocales[shape] }), void 0), _jsx(Row, __assign({ mx: "-8px", flexWrap: "wrap" }, { children: ['enabled', 'hovered', 'focused', 'pressed', 'disabled'].map(function (id) { return (_jsx(Box, __assign({ mx: "8px" }, { children: _jsx(_Button, __assign({}, (buttonPropByTag[id] || {}), ((theme === 'dark' && id === 'enabled' && shape === 'text') && { color: 'white' }), { children: id }), void 0) }), void 0)); }) }), void 0)] }), void 0));
                    }) }, void 0) }), void 0) }), void 0) }, void 0));
};
export default Buttons;
