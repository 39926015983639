import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mac/github/elemental-zcash/react/docs/src/components/layout.js";
import { Buttons } from '@elemental-zcash/design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Buttons mdxType="Buttons" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      